<template>
  <section class="content-container">
      <SectionTabs :tabs="tab_component"/>
    <router-view/>
  </section>
</template>


<script>
  import SectionTabs from "@/components/Section/SectionTabs.vue";
  export default {
    components: { SectionTabs },
    data() {
      return {
        title: "Inicio",
        tab_component: [{
          name: "Dashboard",
          route: "inicio-dash",
        }/* , {
          name: "Personalización",
          route: "Personalización",
        } */]
      };
    }
  };
</script>
